<script>
import Swal from "sweetalert2";
import { mapState } from "vuex";
import { typeOffices, typeAgreement, typeOfficesAutorized, terminalsRoutes, plateDestinations, areas } from '../constants.js'

export default {
  data() {
    return {
      usuario: {},
      typeOffices,
      typeAgreement,
      typeOfficesAutorized,
      terminalsRoutes,
      plateDestinations,
      areas,
      form: {
        dispatchedNumber: "",
        selectedSiteIdTerminal: null,
        preferredPlate: "",
        selectedLevelServiceId: null,
        selectedPlateDestination: null,
        selectedOriginDepartment: "",
        selectedOriginMunicipality: "",
        selectedDestinationDepartment: "",
        selectedDestinationMunicipality: "",
        selectedViaRoute: "",
        selectedPassengerQuantity: "",
        selectedTypeOffice: null,
        selectedTypeOfficeAutorized: null,
        selectedTypeAgreement: null,
        selectedTerminalRoute: null,
        selectedRateValue: "",
      },
      municipalitiesOptions: {
        origin: [],
        destination: [],
      },
    };
  },
  computed: {
    ...mapState("terminales", [
      "codesTerminal",
      "divipolaCodesDepartamentsAndMunicipalities",
      "passengerOptions",
      "allDataTerminals",
    ]),
  },
  watch: {
    "form.selectedOriginDepartment"(newDept) {
      this.loadMunicipalities(newDept, "origin");
    },
    "form.selectedDestinationDepartment"(newDept) {
      this.loadMunicipalities(newDept, "destination");
    },
  },
  methods: {
    loadMunicipalities(departmentCode, type) {
      const department = this.divipolaCodesDepartamentsAndMunicipalities.find(
        (dept) => dept.cod_depto === departmentCode
      );
      this.municipalitiesOptions[type] = department
        ? department.municipalities
        : [];
    },
    async handleCreateOffices(e) {
      e.preventDefault();

      const data = {
        empresaId: this.usuario.empresa.id,
        numeroDespacho: this.form.dispatchedNumber,
        terId: this.form.selectedSiteIdTerminal,
        nitEmpresaTppc1: "123456789-0",
        claseVehiculo: 1,
        nivelServicio: this.form.selectedLevelServiceId,
        placa: this.form.preferredPlate,
        origenPlaca: this.form.selectedPlateDestination,
        dptoOrigenRuta: this.form.selectedOriginDepartment,
        municipioOrigenRuta: this.form.selectedOriginMunicipality,
        dptoDestinoRuta: this.form.selectedDestinationDepartment,
        municipioDestinoRuta: this.form.selectedDestinationMunicipality,
        viaRuta: this.form.selectedViaRoute,
        fechaDespacho: this.$moment
          .tz(new Date(), "America/Bogota")
          .format("YYYY-MM-DDTHH:mm"),
        horaDespacho: this.$moment
          .tz(new Date(), "America/Bogota")
          .format("HH"),
        minutoDespacho: this.$moment
          .tz(new Date(), "America/Bogota")
          .format("mm"),
        pasajerosDespacho: this.form.selectedPassengerQuantity,
        tipoDespacho: this.form.selectedTypeOffice,
        tipoDespachoAutorizado: this.form.selectedTypeOfficeAutorized,
        tipoConvenio: this.form.selectedTypeAgreement,
        nitEmpresaTppc2: "987654321-0",
        nitEmpresaTe: "112233445-0",
        terminalesRecorridoRuta: this.form.selectedTerminalRoute,
        valorTasaUso: Number.parseInt(this.form.selectedRateValue),
      };
      try {
        const response = await this.$store.dispatch("hl_post", {
          path: "Terminales/IngresoDespacho",
          data,
        });

        if (!response.success)
          throw new Error(
            "Error al crear despacho, por favor intente nuevamente. Si el error persiste, comuníquese con soporte técnico."
          );

        this.allDataTerminals["Despachos"].push(data);
        this.$bvModal.hide("modalTerminales");
        Swal.fire(
          "Despacho creado!",
          "El despacho ha sido creado con éxito.",
          "success"
        );
      } catch (error) {
        Swal.fire("Error al crear despacho!", error.message, "error");
      } finally {
        this.resetForm();
      }
    },
    resetForm() {
      this.form = {
        dispatchedNumber: "",
        selectedSiteIdTerminal: null,
        preferredPlate: "",
        selectedLevelServiceId: null,
        selectedPlateDestination: null,
        selectedOriginDepartment: "",
        selectedOriginMunicipality: "",
        selectedDestinationDepartment: "",
        selectedDestinationMunicipality: "",
        selectedViaRoute: "",
        selectedPassengerQuantity: "",
        selectedTypeOffice: null,
        selectedTypeOfficeAutorized: null,
        selectedTypeAgreement: null,
        selectedTerminalRoute: null,
        selectedRateValue: "",
      };
      this.municipalitiesOptions.origin = [];
      this.municipalitiesOptions.destination = [];
    },
  },
  mounted() {
    this.usuario = JSON.parse(localStorage.getItem("setPersonaAct"));
  },
};
</script>

<template>
  <b-container fluid>
    <form @submit="handleCreateOffices">
      <b-row>
        <b-col sm="12">
          <card>
            <!-- Section form 0 -->
            <div class="row mt-4">
              <div class="col-12 col-lg-6 mb-3">
                <label for=" " class="form-label">Número de despacho</label>
                <input
                  v-model="form.dispatchedNumber"
                  placeholder="Ej: 123456789"
                  type="text"
                  class="form-control"
                  maxlength="12"
                  required
                />
              </div>

              <div class="col-12 col-lg-6 mb-3">
                <label for="" class="form-label"> Sede terminal </label>
                <v-select
                  v-model="form.selectedSiteIdTerminal"
                  class="style-chooser"
                  :options="codesTerminal"
                  :reduce="(value) => value.id"
                  :get-option-label="(option) => option.razonSocial"
                  placeholder="-- Selecciona la sede --"
                >
                  <!-- required -->
                  <template #search="{ attributes, events }">
                    <input
                      class="vs__search"
                      :required="!form.selectedSiteIdTerminal"
                      v-bind="attributes"
                      v-on="events"
                    />
                  </template>
                  <!-- sin resultados en la busquedad -->
                  <template v-slot:no-options="{ search, searching }">
                    <template v-if="searching">
                      No se encontraron resultados para
                      <em class="text-danger">{{ search }}</em
                      >.
                    </template>
                    <em v-else style="opacity: 0.5"
                      >No hay datos para mostrar.</em
                    >
                  </template>
                </v-select>
              </div>
            </div>
            <!-- End Section form 0 -->

            <!-- Section form 1-->
            <div class="row mt-4">
              <div class="col-12 col-lg-6 mb-3">
                <label for="" class="form-label"> Nivel de servicio </label>
                <v-select
                  v-model="form.selectedLevelServiceId"
                  class="style-chooser"
                  :options="areas"
                  :reduce="(value) => value.id"
                  placeholder="-- Selecciona nivel de servicio --"
                >
                  <!-- required -->
                  <template #search="{ attributes, events }">
                    <input
                      class="vs__search"
                      :required="!form.selectedLevelServiceId"
                      v-bind="attributes"
                      v-on="events"
                    />
                  </template>
                  <!-- sin resultados en la busquedad -->
                  <template v-slot:no-options="{ search, searching }">
                    <template v-if="searching">
                      No se encontraron resultados para
                      <em class="text-danger">{{ search }}</em
                      >.
                    </template>
                    <em v-else style="opacity: 0.5"
                      >No hay datos para mostrar.</em
                    >
                  </template>
                </v-select>
              </div>

              <div class="col-12 col-lg-6 mb-3">
                <label for=" " class="form-label">Placa</label>
                <input
                  v-model="form.preferredPlate"
                  placeholder="Ej: 1234ABC"
                  type="text"
                  class="form-control"
                  maxlength="7"
                  required
                />
              </div>
            </div>
            <!-- End Section form 1-->

            <!-- Section form 2-->
            <div class="row mt-4">
              <div class="col-12 col-lg-6 mb-3">
                <label for="" class="form-label"> Origen placa </label>
                <v-select
                  v-model="form.selectedPlateDestination"
                  class="style-chooser"
                  :options="plateDestinations"
                  :reduce="(value) => value.id"
                  placeholder="-- Selecciona el origen --"
                >
                  <!-- required -->
                  <template #search="{ attributes, events }">
                    <input
                      class="vs__search"
                      :required="!form.selectedPlateDestination"
                      v-bind="attributes"
                      v-on="events"
                    />
                  </template>
                  <!-- sin resultados en la busquedad -->
                  <template v-slot:no-options="{ search, searching }">
                    <template v-if="searching">
                      No se encontraron resultados para
                      <em class="text-danger">{{ search }}</em
                      >.
                    </template>
                    <em v-else style="opacity: 0.5"
                      >No hay datos para mostrar.</em
                    >
                  </template>
                </v-select>
              </div>

              <div class="col-12 col-lg-6 mb-3">
                <label for=" " class="form-label">Departamento de origen</label>
                <v-select
                  v-model="form.selectedOriginDepartment"
                  class="style-chooser"
                  :options="divipolaCodesDepartamentsAndMunicipalities"
                  :reduce="(value) => value.cod_depto"
                  :get-option-label="(option) => option.dpto"
                  placeholder="-- Selecciona el Departamento --"
                >
                  <!-- required -->
                  <template #search="{ attributes, events }">
                    <input
                      class="vs__search"
                      :required="!form.selectedOriginDepartment"
                      v-bind="attributes"
                      v-on="events"
                    />
                  </template>
                  <!-- sin resultados en la busquedad -->
                  <template v-slot:no-options="{ search, searching }">
                    <template v-if="searching">
                      No se encontraron resultados para
                      <em class="text-danger">{{ search }}</em
                      >.
                    </template>
                    <em v-else style="opacity: 0.5"
                      >No hay datos para mostrar.</em
                    >
                  </template>
                </v-select>
              </div>
            </div>
            <!-- End Section form 2-->

            <!-- Section form 3 -->
            <div class="row mt-4">
              <div class="col-12 col-lg-6 mb-3">
                <label for="" class="form-label">Municipio de origen</label>
                <v-select
                  v-model="form.selectedOriginMunicipality"
                  class="style-chooser"
                  :options="municipalitiesOptions.origin"
                  :reduce="(value) => value.cod_mpio"
                  :get-option-label="(option) => option.nom_mpio"
                  placeholder="-- Selecciona el Municipio --"
                >
                  <!-- required -->
                  <template #search="{ attributes, events }">
                    <input
                      class="vs__search"
                      :required="!form.selectedOriginMunicipality"
                      v-bind="attributes"
                      v-on="events"
                    />
                  </template>
                  <!-- sin resultados en la busquedad -->
                  <template v-slot:no-options="{ search, searching }">
                    <template v-if="searching">
                      No se encontraron resultados para
                      <em class="text-danger">{{ search }}</em
                      >.
                    </template>
                    <em v-else style="opacity: 0.5"
                      >Selecciona el Departamento de Origen.</em
                    >
                  </template>
                </v-select>
              </div>

              <div class="col-12 col-lg-6 mb-3">
                <label for=" " class="form-label"
                  >Departamento de Destino</label
                >
                <v-select
                  v-model="form.selectedDestinationDepartment"
                  class="style-chooser"
                  :options="divipolaCodesDepartamentsAndMunicipalities"
                  :reduce="(value) => value.cod_depto"
                  :get-option-label="(option) => option.dpto"
                  placeholder="-- Selecciona el Departamento --"
                >
                  <!-- required -->
                  <template #search="{ attributes, events }">
                    <input
                      class="vs__search"
                      :required="!form.selectedDestinationDepartment"
                      v-bind="attributes"
                      v-on="events"
                    />
                  </template>
                  <!-- sin resultados en la busquedad -->
                  <template v-slot:no-options="{ search, searching }">
                    <template v-if="searching">
                      No se encontraron resultados para
                      <em class="text-danger">{{ search }}</em
                      >.
                    </template>
                    <em v-else style="opacity: 0.5"
                      >No hay datos para mostrar.</em
                    >
                  </template>
                </v-select>
              </div>
            </div>
            <!-- End Section form 3 -->

            <!-- Section form 4 -->
            <div class="row mt-4">
              <div class="col-12 col-lg-6 mb-3">
                <label for=" " class="form-label">Municipio de destino</label>
                <v-select
                  v-model="form.selectedDestinationMunicipality"
                  class="style-chooser"
                  :options="municipalitiesOptions.destination"
                  :reduce="(value) => value.cod_mpio"
                  :get-option-label="(option) => option.nom_mpio"
                  placeholder="-- Selecciona el Municipio --"
                >
                  <!-- required -->
                  <template #search="{ attributes, events }">
                    <input
                      class="vs__search"
                      :required="!form.selectedDestinationMunicipality"
                      v-bind="attributes"
                      v-on="events"
                    />
                  </template>
                  <!-- sin resultados en la busquedad -->
                  <template v-slot:no-options="{ search, searching }">
                    <template v-if="searching">
                      No se encontraron resultados para
                      <em class="text-danger">{{ search }}</em
                      >.
                    </template>
                    <em v-else style="opacity: 0.5"
                      >Selecciona el Departamento de Destino.</em
                    >
                  </template>
                </v-select>
              </div>

              <div class="col-12 col-lg-6 mb-3">
                <label for=" " class="form-label"> Via ruta </label>
                <input
                  v-model="form.selectedViaRoute"
                  type="text"
                  class="form-control"
                  placeholder="Ej: Avenida Simón Bolívar"
                  required
                />
              </div>
            </div>
            <!-- End Section form 4 -->

            <!-- Section form 5 -->
            <div class="row mt-4">
              <div class="col-12 col-lg-6 mb-3">
                <label for=" " class="form-label">Cantidad de pasajeros</label>
                <v-select
                  v-model="form.selectedPassengerQuantity"
                  class="style-chooser"
                  :options="passengerOptions"
                  :reduce="(value) => value.id"
                  placeholder="-- Selecciona cantidad de pasajeros --"
                >
                  <!-- required -->
                  <template #search="{ attributes, events }">
                    <input
                      class="vs__search"
                      :required="!form.selectedPassengerQuantity"
                      v-bind="attributes"
                      v-on="events"
                    />
                  </template>
                  <!-- sin resultados en la busquedad -->
                  <template v-slot:no-options="{ search, searching }">
                    <template v-if="searching">
                      No se encontraron resultados para
                      <em class="text-danger">{{ search }}</em
                      >.
                    </template>
                    <em v-else style="opacity: 0.5"
                      >No hay datos para mostrar.</em
                    >
                  </template>
                </v-select>
              </div>
              <div class="col-12 col-lg-6 mb-3">
                <label for="" class="form-label"> Tipo de despacho </label>
                <v-select
                  v-model="form.selectedTypeOffice"
                  class="style-chooser"
                  placeholder="-- Selecciona el tipo de despacho --"
                  :options="typeOffices"
                  :reduce="(value) => value.id"
                >
                  <!-- required -->
                  <template #search="{ attributes, events }">
                    <input
                      class="vs__search"
                      :required="!form.selectedTypeOffice"
                      v-bind="attributes"
                      v-on="events"
                    />
                  </template>
                  <!-- sin resultados en la busquedad -->
                  <template v-slot:no-options="{ search, searching }">
                    <template v-if="searching">
                      No se encontraron resultados para
                      <em class="text-danger">{{ search }}</em
                      >.
                    </template>
                    <em v-else style="opacity: 0.5"
                      >No hay datos para mostrar.</em
                    >
                  </template>
                </v-select>
              </div>
            </div>
            <!-- End Section form 5 -->

            <!-- Section form 6 -->
            <div class="row mt-4">
              <div class="col-12 col-lg-6 mb-3">
                <label for="" class="form-label">
                  Tipo de despacho Autorizado
                </label>
                <v-select
                  v-model="form.selectedTypeOfficeAutorized"
                  class="style-chooser"
                  placeholder="-- Selecciona el tipo de despacho --"
                  :options="typeOfficesAutorized"
                  :reduce="(value) => value.id"
                >
                  <!-- required -->
                  <template #search="{ attributes, events }">
                    <input
                      class="vs__search"
                      :required="!form.selectedTypeOfficeAutorized"
                      v-bind="attributes"
                      v-on="events"
                    />
                  </template>
                  <!-- sin resultados en la busquedad -->
                  <template v-slot:no-options="{ search, searching }">
                    <template v-if="searching">
                      No se encontraron resultados para
                      <em class="text-danger">{{ search }}</em
                      >.
                    </template>
                    <em v-else style="opacity: 0.5"
                      >No hay datos para mostrar.</em
                    >
                  </template>
                </v-select>
              </div>

              <div class="col-12 col-lg-6 mb-3">
                <label for="" class="form-label"> Tipo de convenio </label>
                <v-select
                  v-model="form.selectedTypeAgreement"
                  class="style-chooser"
                  :options="typeAgreement"
                  :reduce="(value) => value.id"
                  placeholder="-- Selecciona el tipo de convenio --"
                >
                  <!-- required -->
                  <template #search="{ attributes, events }">
                    <input
                      class="vs__search"
                      :required="!form.selectedTypeAgreement"
                      v-bind="attributes"
                      v-on="events"
                    />
                  </template>
                  <!-- sin resultados en la busquedad -->
                  <template v-slot:no-options="{ search, searching }">
                    <template v-if="searching">
                      No se encontraron resultados para
                      <em class="text-danger">{{ search }}</em
                      >.
                    </template>
                    <em v-else style="opacity: 0.5"
                      >No hay datos para mostrar.</em
                    >
                  </template>
                </v-select>
                <small class="mt-2" style="display: block; text-align: start">
                  Se debe diligenciar de forma obligatoria para los despachos de
                  convenios de colaboración empresarial
                </small>
              </div>
            </div>
            <!-- End Section form 6 -->

            <!-- Section form 7 -->
            <div class="row mt-4">
              <div class="col-12 col-lg-6 mb-3">
                <label for="" class="form-label">
                  Terminales recorrido de rutas
                </label>
                <v-select
                  v-model="form.selectedTerminalRoute"
                  class="style-chooser"
                  :options="terminalsRoutes"
                  :reduce="(value) => value.id"
                  placeholder="-- Selecciona recorrido --"
                >
                  <!-- required -->
                  <template #search="{ attributes, events }">
                    <input
                      class="vs__search"
                      :required="!form.selectedTerminalRoute"
                      v-bind="attributes"
                      v-on="events"
                    />
                  </template>
                  <!-- sin resultados en la busquedad -->
                  <template v-slot:no-options="{ search, searching }">
                    <template v-if="searching">
                      No se encontraron resultados para
                      <em class="text-danger">{{ search }}</em
                      >.
                    </template>
                    <em v-else style="opacity: 0.5"
                      >No hay datos para mostrar.</em
                    >
                  </template>
                </v-select>
              </div>

              <div class="col-12 col-lg-6 mb-3">
                <label for=" " class="form-label"> Valor tasa de uso </label>
                <input
                  v-model="form.selectedRateValue"
                  type="text"
                  class="form-control"
                  placeholder="Ej: 123456789"
                  maxlength="12"
                  required
                />
              </div>
            </div>
            <!-- End Section form 7 -->

            <div class="d-flex justify-content-end pt-5">
              <vs-button
                type="submit"
                class="py-1"
                animation-type="vertical"
                gradient
                size="large"
              >
                Agregar Despacho
              </vs-button>
            </div>
          </card>
        </b-col>
      </b-row>
    </form>
  </b-container>
</template>

<script>
import Swal from "sweetalert2";
import { mapState } from "vuex";
import { resultsExams, breathalyzerGrade, medicalExams, breathalyzerDrivers } from '../constants.js'

export default {
  data() {
    return {
      resultsExams,
      breathalyzerGrade,
      medicalExams,
      breathalyzerDrivers,
      usuario: {},
      form: {
        dispatchedNumber: "",
        nitOperator: "",
        selectedSiteIdTerminal: null,
        selectedDocumentTypeDriver: null,
        nameDriver: "",
        documentNumber: "",
        selectedResultBreathalyzer: null,
        selectedMedicalExam: null,
        hourExam: "",
        minuteExam: "",
        selectedBreathalyzerGrade: null,
        selectedResultExam: null,
        selectedSocialReason: "",
      },
    };
  },
  computed: {
    ...mapState("terminales", [
      "codesTerminal",
      "listTypesDocs",
      "allDataTerminals",
    ]),
  },

  methods: {
    resetForm() {
      this.form = {
        dispatchedNumber: "",
        nitOperator: "",
        selectedSiteIdTerminal: null,
        selectedDocumentTypeDriver: null,
        nameDriver: "",
        documentNumber: "",
        selectedResultBreathalyzer: null,
        selectedMedicalExam: null,
        hourExam: "",
        minuteExam: "",
        selectedBreathalyzerGrade: null,
        selectedResultExam: null,
        selectedSocialReason: "",
      };
    },
    async handleCreateBreathalyzers(e) {
      e.preventDefault();

      const data = {
        empresaId: this.usuario.empresa.id,
        numeroDespacho: this.form.dispatchedNumber,
        nitOperador: "123456789-0",
        razonSocialOperador: this.form.selectedSocialReason,
        terId: this.form.selectedSiteIdTerminal,
        tipoDocumentoConductor: this.form.selectedDocumentTypeDriver,
        numeroDocumentoConductor: this.form.documentNumber,
        nombreConductor: this.form.nameDriver,
        fechaPrueba: this.$moment
          .tz(new Date(), "America/Bogota")
          .format("YYYY-MM-DDTHH:mm"),
        horaPrueba: this.$moment.tz(new Date(), "America/Bogota").format("HH"),
        minutoPrueba: this.$moment
          .tz(new Date(), "America/Bogota")
          .format("mm"),
        resultadosPrueba: this.form.selectedResultBreathalyzer,
        gradoAlcoholimia: this.form.selectedBreathalyzerGrade,
        examenMedico: this.form.selectedMedicalExam,
        horaExamen: this.form.hourExam,
        minutoExamen: this.form.minuteExam,
        resultadosExamen: this.form.selectedResultExam,
      };

      try {
        const response = await this.$store.dispatch("hl_post", {
          path: "Terminales/IngresoAlcoholimetria",
          data,
        });

        if (!response.success)
          throw new Error(
            "Error al crear Alcoholimetria, por favor intente nuevamente. Si el error persiste, comuníquese con soporte técnico."
          );
        this.allDataTerminals["Alcoholimetrias"].push(data);
        this.$bvModal.hide("modalTerminales");
        Swal.fire(
          "Alcoholimetria creada!",
          "La Alcoholimetria ha sido creada con éxito.",
          "success"
        );
      } catch (error) {
        Swal.fire("Error al crear Alcoholimetria!", error.message, "error");
      } finally {
        this.resetForm();
      }
    },
  },

  mounted() {
    this.usuario = JSON.parse(localStorage.getItem("setPersonaAct"));
  },
};
</script>

<template>
  <b-container fluid>
    <form @submit="handleCreateBreathalyzers">
      <b-row>
        <b-col sm="12">
          <card>
            <!-- Section form 0 -->
            <div class="row mt-4">
              <div class="col-12 col-lg-6 mb-3">
                <label for=" " class="form-label">Número de despacho</label>
                <input
                  v-model="form.dispatchedNumber"
                  type="text"
                  class="form-control"
                  placeholder="Ej: 123456890122"
                  maxlength="12"
                  required
                />
              </div>

              <div class="col-12 col-lg-6 mb-3">
                <label for=" " class="form-label">NIT operador</label>
                <input
                  v-model="form.nitOperator"
                  type="text"
                  class="form-control"
                  placeholder="Ej: 10122012334-0"
                  maxlength="9"
                  required
                />
              </div>
            </div>
            <!-- End Section form 0 -->

            <!-- Section form 0.5 -->
            <div class="row mt-4">
              <div class="col-12 col-lg-6 mb-3">
                <label for="" class="form-label"> Sede terminal </label>
                <v-select
                  v-model="form.selectedSiteIdTerminal"
                  class="style-chooser"
                  :options="codesTerminal"
                  :reduce="(value) => value.id"
                  :get-option-label="(option) => option.razonSocial"
                  placeholder="-- Selecciona la sede --"
                >
                  <!-- required -->
                  <template #search="{ attributes, events }">
                    <input
                      class="vs__search"
                      :required="!form.selectedSiteIdTerminal"
                      v-bind="attributes"
                      v-on="events"
                    />
                  </template>
                  <!-- sin resultados en la busquedad -->
                  <template v-slot:no-options="{ search, searching }">
                    <template v-if="searching">
                      No se encontraron resultados para
                      <em class="text-danger">{{ search }}</em
                      >.
                    </template>
                    <em v-else style="opacity: 0.5"
                      >No hay datos para mostrar.</em
                    >
                  </template>
                </v-select>
              </div>

              <div class="col-12 col-lg-6 mb-3">
                <label for="" class="form-label">
                  Tipo de documento del conductor
                </label>
                <v-select
                  v-model="form.selectedDocumentTypeDriver"
                  class="style-chooser"
                  :options="listTypesDocs"
                  :reduce="(value) => value.id"
                  :get-option-label="(option) => option.descripcion"
                  placeholder="-- Selecciona tipo documento --"
                >
                  <!-- required -->
                  <template #search="{ attributes, events }">
                    <input
                      class="vs__search"
                      :required="!form.selectedDocumentTypeDriver"
                      v-bind="attributes"
                      v-on="events"
                    />
                  </template>
                  <!-- sin resultados en la busquedad -->
                  <template v-slot:no-options="{ search, searching }">
                    <template v-if="searching">
                      No se encontraron resultados para
                      <em class="text-danger">{{ search }}</em
                      >.
                    </template>
                    <em v-else style="opacity: 0.5"
                      >No hay datos para mostrar.</em
                    >
                  </template>
                </v-select>
              </div>
            </div>
            <!-- End Section form 0.5 -->

            <!-- Section form 1-->
            <div class="row mt-4">
              <div class="col-12 col-lg-6 mb-3">
                <label for=" " class="form-label">Nombre del conductor</label>
                <input
                  v-model="form.nameDriver"
                  type="text"
                  class="form-control"
                  placeholder="Ej: Juan Perez"
                  required
                />
              </div>
              <div class="col-12 col-lg-6 mb-3">
                <label for=" " class="form-label"> Número de documento</label>
                <input
                  v-model="form.documentNumber"
                  type="text"
                  class="form-control"
                  placeholder="Ej: 123456789"
                  required
                />
              </div>
            </div>
            <!-- End Section form 1-->

            <!-- Section form 4-->
            <div class="row mt-4">
              <div class="col-12 col-lg-6 mb-3">
                <label for="" class="form-label">
                  Resultado de las prueba
                </label>
                <v-select
                  v-model="form.selectedResultBreathalyzer"
                  class="style-chooser"
                  :options="breathalyzerDrivers"
                  :reduce="(value) => value.id"
                  placeholder="-- Selecciona resultados de la prueba --"
                >
                  <!-- required -->
                  <template #search="{ attributes, events }">
                    <input
                      class="vs__search"
                      :required="!form.selectedResultBreathalyzer"
                      v-bind="attributes"
                      v-on="events"
                    />
                  </template>
                  <!-- sin resultados en la busquedad -->
                  <template v-slot:no-options="{ search, searching }">
                    <template v-if="searching">
                      No se encontraron resultados para
                      <em class="text-danger">{{ search }}</em
                      >.
                    </template>
                    <em v-else style="opacity: 0.5"
                      >No hay datos para mostrar.</em
                    >
                  </template>
                </v-select>
              </div>

              <div class="col-12 col-lg-6 mb-3">
                <label for="" class="form-label"> Examen médico </label>
                <v-select
                  v-model="form.selectedMedicalExam"
                  class="style-chooser"
                  :options="medicalExams"
                  :reduce="(value) => value.id"
                  placeholder="-- Selecciona una opcion --"
                >
                  <!-- required -->
                  <template #search="{ attributes, events }">
                    <input
                      class="vs__search"
                      :required="!form.selectedMedicalExam"
                      v-bind="attributes"
                      v-on="events"
                    />
                  </template>
                  <!-- sin resultados en la busquedad -->
                  <template v-slot:no-options="{ search, searching }">
                    <template v-if="searching">
                      No se encontraron resultados para
                      <em class="text-danger">{{ search }}</em
                      >.
                    </template>
                    <em v-else style="opacity: 0.5"
                      >No hay datos para mostrar.</em
                    >
                  </template>
                </v-select>
              </div>
            </div>
            <!-- End Section form 4 -->

            <div class="row mt-4">
              <div class="col-12 col-lg-6 mb-3">
                <label for=" " class="form-label">Hora de examen</label>
                <input
                  v-model="form.hourExam"
                  type="text"
                  class="form-control"
                  placeholder="2"
                  maxlength="2"
                  required
                />
              </div>

              <div class="col-12 col-lg-6 mb-3">
                <label for=" " class="form-label">Minuto de examen</label>
                <input
                  v-model="form.minuteExam"
                  type="text"
                  class="form-control"
                  placeholder="5"
                  maxlength="10"
                  required
                />
              </div>
            </div>

            <!-- Section form 4-->
            <div class="row mt-4">
              <div class="col-12 col-lg-6 mb-3">
                <label for="" class="form-label">
                  Grados de Alcoholimetría
                </label>
                <v-select
                  v-model="form.selectedBreathalyzerGrade"
                  class="style-chooser"
                  :options="breathalyzerGrade"
                  :reduce="(value) => value.id"
                  placeholder="-- Selecciona grado--"
                >
                  <!-- required -->
                  <template #search="{ attributes, events }">
                    <input
                      class="vs__search"
                      :required="!form.selectedBreathalyzerGrade"
                      v-bind="attributes"
                      v-on="events"
                    />
                  </template>
                  <!-- sin resultados en la busquedad -->
                  <template v-slot:no-options="{ search, searching }">
                    <template v-if="searching">
                      No se encontraron resultados para
                      <em class="text-danger">{{ search }}</em
                      >.
                    </template>
                    <em v-else style="opacity: 0.5"
                      >No hay datos para mostrar.</em
                    >
                  </template>
                </v-select>
              </div>

              <div class="col-12 col-lg-6 mb-3">
                <label for="" class="form-label"> Resultado de examen </label>
                <v-select
                  v-model="form.selectedResultExam"
                  class="style-chooser"
                  :options="resultsExams"
                  :reduce="(value) => value.id"
                  placeholder="-- Selecciona resultado  --"
                >
                  <!-- required -->
                  <template #search="{ attributes, events }">
                    <input
                      class="vs__search"
                      :required="!form.selectedResultExam"
                      v-bind="attributes"
                      v-on="events"
                    />
                  </template>
                  <!-- sin resultados en la busquedad -->
                  <template v-slot:no-options="{ search, searching }">
                    <template v-if="searching">
                      No se encontraron resultados para
                      <em class="text-danger">{{ search }}</em
                      >.
                    </template>
                    <em v-else style="opacity: 0.5"
                      >No hay datos para mostrar.</em
                    >
                  </template>
                </v-select>
              </div>
            </div>
            <!-- End Section form 4 -->

            <!-- Section form 6 -->
            <div class="row mt-4">
              <div class="col-12 mb-3">
                <label for=" " class="form-label">Razon social</label>
                <textarea
                  v-model="form.selectedSocialReason"
                  type="text"
                  class="form-control"
                  placeholder="Ej: Empresa de transporte"
                  rows="5"
                  required
                  style="resize: none"
                ></textarea>
              </div>
            </div>
            <!-- End Section form 6 -->

            <div class="d-flex justify-content-end pt-5">
              <vs-button
                type="submit"
                class="py-1"
                animation-type="vertical"
                gradient
                size="large"
              >
                Agregar Alcoholimetría
              </vs-button>
            </div>
          </card>
        </b-col>
      </b-row>
    </form>
  </b-container>
</template>

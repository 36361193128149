<script>
// import { data as mocksTest } from "./utils/__mocks__.js";
import { mapState } from "vuex";
import {
  getNameMunicipalityDestiny,
  getNameMunicipalityOrigin,
  getNameDepartamentOrigin,
  getNameDepartamentDestiny,
} from "../constants";

export default {
  computed: {
    ...mapState("terminales", [
      "allDataTerminals",
      "divipolaCodesDepartamentsAndMunicipalities",
    ]),
  },
  methods: {
    getNameMunicipalityDestiny,
    getNameMunicipalityOrigin,
    getNameDepartamentOrigin,
    getNameDepartamentDestiny,
  },
};
</script>

<template>
  <div class="row" style="gap: 0.5rem">
    <card
      v-for="item of allDataTerminals['Despachos']"
      :key="item.id"
      class="bg-dark p-3 border col-12 col-lg-2"
    >
      <div class="row">
        <div
          class="col-12 d-flex justify-content-end align-items-center"
          style="gap: 0.125rem"
        >
          <i class="fas fa-align-right turbo-bus"></i>
          <i class="fas fa-van-shuttle"></i>
        </div>
        <div class="col-12">
          <small class="text-white-50">Placa</small>
          <h5>{{ item.placa }}</h5>
        </div>
        <div class="col-12 py-2" style="align-items: center; gap: 0.3rem">
          <div class="d-flex" style="align-items: center; gap: 0.2rem">
            <i class="ri-map-pin-line"></i>
            <small>
              {{
                getNameMunicipalityOrigin(
                  item,
                  divipolaCodesDepartamentsAndMunicipalities
                )
              }}
            </small>
            <i> , </i>
            <small>
              {{
                getNameDepartamentOrigin(
                  item,
                  divipolaCodesDepartamentsAndMunicipalities
                )
              }}
            </small>
          </div>
          <i class="ri-more-2-line"></i>
          <div class="d-flex" style="align-items: center; gap: 0.2rem">
            <i class="ri-map-pin-fill"></i>
            <small>
              {{
                getNameMunicipalityDestiny(
                  item,
                  divipolaCodesDepartamentsAndMunicipalities
                )
              }}
            </small>
            <i> , </i>
            <small>
              {{
                getNameDepartamentDestiny(
                  item,
                  divipolaCodesDepartamentsAndMunicipalities
                )
              }}
            </small>
          </div>
        </div>
        <div class="col-12">
          <small class="text-white-50">Fecha despacho</small>
          <br />
          <small>
            {{
              $moment
                .tz(item.fechaDespacho, "America/Bogota")
                .format("D MMM, YYYY / hh:mm A")
            }}
          </small>
        </div>
      </div>
    </card>
  </div>
</template>

<style scoped>
.turbo-bus {
  opacity: 0.7;
  font-size: 0.7rem;
}
</style>

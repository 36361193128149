<script>
import { methods } from "./constants.js";
import ContainerData from "./container-data.vue";
import Offices from "./pages/offices.vue";
import OfficesForm from "./forms/offices-form.vue";

import Breathalyzers from "./pages/breathalyzers.vue";
import BreathalyzersForm from "./forms/breathalyzers-form.vue";

import Arrivals from "./pages/arrivals.vue";
import ArrivalsForm from "./forms/arrivals-form.vue";

import BtnAddCardTable from "@/components/btnCardTable/btnAddCardTable.vue";
import { mapState } from "vuex";

export default {
  components: {
    ContainerData,
    Offices,
    Breathalyzers,
    Arrivals,
    OfficesForm,
    BreathalyzersForm,
    ArrivalsForm,
    BtnAddCardTable,
  },
  data() {
    return {
      currentMethod: {},
      methods,
      txtSearch: "",
      ID_OFFICES: 1,
      ID_BREATHALYZERS: 2,
      ID_ARRIVALS: 3,
      activeDataNotFound: false,
      cacheData: [],
    };
  },

  computed: {
    ...mapState("terminales", ["allDataTerminals"]),
  },

  methods: {
    handlerActiveFase(method) {
      sessionStorage.setItem("currentMethod", JSON.stringify(method));
      this.currentMethod = method;
      this.cacheData = this.allDataTerminals[this.currentMethod.name].slice();
    },

    async fetchModules() {
      await this.$store.dispatch("terminales/getCodesTerminal");
      await this.$store.dispatch("terminales/getDivipolaCodes");
      await this.$store.dispatch("terminales/getListTypesDocs");
      await this.$store.dispatch("terminales/getAllDataTerminals");
      this.$store.dispatch("terminales/getPassengerOptions");

      this.cacheData = this.allDataTerminals[this.currentMethod.name].slice();
    },
    async handleSearchCurrentTerminals() {
      /**@typedef {import('./types').Offices} Offices */
      /**@typedef {import('./types').Breathalyzers} Breathalyzers */
      /**@typedef {import('./types').Arrivals} Arrivals */

      if (this.txtSearch && this.currentMethod.id === this.ID_OFFICES) {
        /**@type {Offices[]} */
        const officesData = this.allDataTerminals["Despachos"];

        const filtereofficesData = officesData.filter((officeItem) => {
          const plate = officeItem.placa.toLowerCase();
          return plate.includes(this.txtSearch.toLowerCase());
        });

        if (filtereofficesData.length) {
          this.allDataTerminals["Despachos"] = filtereofficesData;
        }
      } else if (
        this.txtSearch &&
        this.currentMethod.id === this.ID_BREATHALYZERS
      ) {
        /**@type {Breathalyzers[]} */
        const breathalyzersData = this.allDataTerminals["Alcoholimetrias"];

        const filtereofficesData = breathalyzersData.filter(
          (breathalyzerItem) => {
            const nameDriver = breathalyzerItem.nombreConductor.toLowerCase();
            return nameDriver.includes(this.txtSearch.toLowerCase());
          }
        );

        if (filtereofficesData.length) {
          this.allDataTerminals["Alcoholimetrias"] = filtereofficesData;
        }
      } else if (this.txtSearch && this.currentMethod.id === this.ID_ARRIVALS) {
        /**@type {Arrivals[]} */
        const arrivalsData = this.allDataTerminals["Llegadas"];

        const filtereofficesData = arrivalsData.filter((arrivalItem) => {
          const plate = arrivalItem.placa.toLowerCase();
          return plate.includes(this.txtSearch.toLowerCase());
        });

        if (filtereofficesData.length) {
          this.allDataTerminals["Llegadas"] = filtereofficesData;
        }
      } else {
        this.allDataTerminals[this.currentMethod.name] = this.cacheData;
      }
    },
  },
  mounted() {
    const getCurrentMethodStorage =
      JSON.parse(sessionStorage.getItem("currentMethod")) ?? this.methods[0];

    this.currentMethod = getCurrentMethodStorage;
    this.fetchModules();
  },
};
</script>

<template>
  <card>
    <div class="custom-container">
      <h3
        class="p-3 d-flex flex-column flex-lg-row align-items-center justify-content-between"
      >
        <b> Sistema de Gestión : Terminales </b>
      </h3>
      <hr />
      <div class="row mx-3 align-items-center" style="gap: 0.5rem">
        <div
          v-for="method of methods"
          :key="method.id"
          class="col-12 col-lg-3 bg-dark bg-dark-hover p-3 rounded d-flex flex-column justify-content-between"
          :style="{
            transition: 'box-shadow 0.3s ease',
            boxShadow:
              currentMethod.id == method.id
                ? 'inset 0 0 0 2px #1A5CFF'
                : 'none',
            cursor: 'default',
          }"
          @click="handlerActiveFase(method)"
        >
          <h5>
            {{ method.name }}
          </h5>
          <p class="text-white-50">{{ method.description }}</p>
        </div>
        <!-- btn add  -->
        <div class="col-12 col-lg">
          <BtnAddCardTable
            class="py-5"
            v-b-modal.modalTerminales
            :text="` Ingresar ${currentMethod.name}`"
          />
        </div>
      </div>

      <div class="row my-5">
        <div class="col-12 d-flex align-items-center justify-content-center">
          <div style="position: relative">
            <input
              v-model="txtSearch"
              type="search"
              id="inputSearch"
              class="form-control"
              placeholder="Buscar..."
              @input="handleSearchCurrentTerminals"
            />
            <vs-avatar
              circle
              dark
              id="icon-search"
              class="d-flex align-items-center justify-content-center"
              type="submit"
            >
              <i class="fas fa-search fa-fade"></i>
            </vs-avatar>
          </div>
        </div>
      </div>

      <ContainerData v-if="currentMethod.id === ID_OFFICES">
        <Offices />
      </ContainerData>
      <ContainerData v-if="currentMethod.id === ID_BREATHALYZERS">
        <Breathalyzers />
      </ContainerData>
      <ContainerData v-if="currentMethod.id === ID_ARRIVALS">
        <Arrivals />
      </ContainerData>

      <b-modal
        id="modalTerminales"
        size="xl"
        hide-footer
        hide-header
        body-class="body-modal-custom"
      >
        <OfficesForm v-if="currentMethod.id === ID_OFFICES" />
        <BreathalyzersForm v-if="currentMethod.id === ID_BREATHALYZERS" />
        <ArrivalsForm v-if="currentMethod.id === ID_ARRIVALS" />
      </b-modal>
    </div>
  </card>
</template>

<style lang="css">
.bg-button-dark {
  background-color: #10213b;
}

#inputSearch {
  width: 380px;
  max-width: 430px;
  height: 50px;
  border-radius: 30px;
  font-size: 1.2rem;
  font-weight: 700;
  transition: all 0.5s;
  border: none;
}

#inputSearch:focus {
  width: 430px;
  box-shadow: inset 0 0 0 2px rgb(242, 19, 93);
}

#inputSearch::placeholder {
  font-size: 1.2rem;
  font-weight: 700;
}

#icon-search {
  position: absolute;
  transition: 0.5s;
  top: 50%;
  transform: translate(0, -50%);
  right: 10px !important;
}
</style>

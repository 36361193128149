var render = function render(){var _vm=this,_c=_vm._self._c;return _c('card',[_c('div',{staticClass:"custom-container"},[_c('h3',{staticClass:"p-3 d-flex flex-column flex-lg-row align-items-center justify-content-between"},[_c('b',[_vm._v(" Sistema de Gestión : Terminales ")])]),_c('hr'),_c('div',{staticClass:"row mx-3 align-items-center",staticStyle:{"gap":"0.5rem"}},[_vm._l((_vm.methods),function(method){return _c('div',{key:method.id,staticClass:"col-12 col-lg-3 bg-dark bg-dark-hover p-3 rounded d-flex flex-column justify-content-between",style:({
          transition: 'box-shadow 0.3s ease',
          boxShadow:
            _vm.currentMethod.id == method.id
              ? 'inset 0 0 0 2px #1A5CFF'
              : 'none',
          cursor: 'default',
        }),on:{"click":function($event){return _vm.handlerActiveFase(method)}}},[_c('h5',[_vm._v(" "+_vm._s(method.name)+" ")]),_c('p',{staticClass:"text-white-50"},[_vm._v(_vm._s(method.description))])])}),_c('div',{staticClass:"col-12 col-lg"},[_c('BtnAddCardTable',{directives:[{name:"b-modal",rawName:"v-b-modal.modalTerminales",modifiers:{"modalTerminales":true}}],staticClass:"py-5",attrs:{"text":` Ingresar ${_vm.currentMethod.name}`}})],1)],2),_c('div',{staticClass:"row my-5"},[_c('div',{staticClass:"col-12 d-flex align-items-center justify-content-center"},[_c('div',{staticStyle:{"position":"relative"}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.txtSearch),expression:"txtSearch"}],staticClass:"form-control",attrs:{"type":"search","id":"inputSearch","placeholder":"Buscar..."},domProps:{"value":(_vm.txtSearch)},on:{"input":[function($event){if($event.target.composing)return;_vm.txtSearch=$event.target.value},_vm.handleSearchCurrentTerminals]}}),_c('vs-avatar',{staticClass:"d-flex align-items-center justify-content-center",attrs:{"circle":"","dark":"","id":"icon-search","type":"submit"}},[_c('i',{staticClass:"fas fa-search fa-fade"})])],1)])]),(_vm.currentMethod.id === _vm.ID_OFFICES)?_c('ContainerData',[_c('Offices')],1):_vm._e(),(_vm.currentMethod.id === _vm.ID_BREATHALYZERS)?_c('ContainerData',[_c('Breathalyzers')],1):_vm._e(),(_vm.currentMethod.id === _vm.ID_ARRIVALS)?_c('ContainerData',[_c('Arrivals')],1):_vm._e(),_c('b-modal',{attrs:{"id":"modalTerminales","size":"xl","hide-footer":"","hide-header":"","body-class":"body-modal-custom"}},[(_vm.currentMethod.id === _vm.ID_OFFICES)?_c('OfficesForm'):_vm._e(),(_vm.currentMethod.id === _vm.ID_BREATHALYZERS)?_c('BreathalyzersForm'):_vm._e(),(_vm.currentMethod.id === _vm.ID_ARRIVALS)?_c('ArrivalsForm'):_vm._e()],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }
export const methods = [
  {
    id: 1,
    name: "Despachos",
    description: "Ingreso de información de los despachos de pasajeros.",
    image: "https://i.ibb.co/7bJ2J2V/ingreso-de-despachos.png",
  },
  {
    id: 2,
    name: "Alcoholimetrias",
    description:
      "Ingreso de la información de las pruebas de alcoholimetría practicadas a los conductores.",
    image: "https://i.ibb.co/7bJ2J2V/ingreso-de-despachos.png",
  },
  {
    id: 3,
    name: "Llegadas",
    description:
      "Ingreso de la información del registro de llegada de los vehículos.",
    image: "https://i.ibb.co/7bJ2J2V/ingreso-de-despachos.png",
  },
];

export const typeArrivals = [
    { id: 1, label: "1. Transito" },
    { id: 2, label: "2. Destino final" },
]

export const typeOffices = [
    { id: 1, label: "1. Si el despacho de la ruta es en origen" },
    { id: 2, label: "2. Si el despacho de la ruta es en transito" },
]

export const typeAgreement = [
      {
        id: 1,
        label:
          "1. Si es despacho en convenio autorizado por el ministerio de transporte por acto administrativo ",
      },
      {
        id: 2,
        label: "2. Si el despacho en convenio deemergencia",
      },
]

export const typeOfficesAutorized = [
      {
        id: 1,
        label:
          "1. Si el vehículo despachado está vinculado a la empresa de TPPC que tiene la ruta autorizada",
      },
      {
        id: 2,
        label:
          "2. Convenio empresarial. Si el vehículo despachado está vinculado a empresa de TPPC diferente a la que tiene la ruta autorizada y que tiene convenio vigente suscrito entre las dos empresas de TPPC.",
      },
      {
        id: 3,
        label:
          "3. Contratos empresarial. Si el vehículo despachado está vinculado a empresa de transporte especial y el despacho se realiza en virtud de contrato vigente entre la empresa de TPPC que tiene la ruta autorizada y la empresa de transporte especial.",
      },
]

export const terminalsRoutes = [
      {
        id: 1,
        label: "1. Para las rutas que tiene únicamente terminal en el origen",
      },
      { id: 2, label: "2. Para las rutas que tienen terminales" },
]

export const resultsExams =  [
    { id: 1, label: "1. Aprobado" },
    { id: 2, label: "2. No aprobado" },
]

export const breathalyzerGrade = [
      { id: 1, label: "1. Grado 0" },
      { id: 2, label: "2. Grado 1" },
      { id: 3, label: "3. Grado 2" },
      { id: 4, label: "4. Grado 3" },
]

export const medicalExams = [
      { id: 1, label: "1. SI" },
      { id: 2, label: "2. NO" },
]

export const breathalyzerDrivers = [
      { id: 1, label: "1. Positivo" },
      { id: 2, label: "2. Negativo" },
]

export const plateDestinations = [
      { id: 1, label: "1. Nacional" },
      { id: 2, label: "2. Internacional" },
    ]

export const areas = [
      { id: 1, label: "1. Basico - Corriente " },
      { id: 2, label: "2. Lujo" },
      { id: 3, label: "3. Corriente directo" },
]

export const getNameDepartamentOrigin = (_item, divipola) => {
  let name = "";

  for (const _code of divipola) {
    if (_code.cod_depto === _item.dptoOrigenRuta) {
      name = _code.dpto;
    }
  }

  return name ? name : _item.dptoOrigenRuta;
};

export const getNameMunicipalityOrigin = (_item, divipola) => {
  let name = "";
  const foundMunicipality = divipola.map(
    (_itemOrigin) => _itemOrigin.municipalities
  );

  foundMunicipality.forEach((x) => {
    x.forEach((y) => {
      if (y.cod_mpio === _item.municipioOrigenRuta) {
        name = y.nom_mpio;
      }
    });
  });

  return foundMunicipality ? name : _item.municipioOrigenRuta;
};

export const getNameDepartamentDestiny = (_item, divipola) => {
  let name = "";

  for (const _code of divipola) {
    if (_code.cod_depto === _item.dptoDestinoRuta) {
      name = _code.dpto;
    }
  }

  return name ? name : _item.dptoDestinoRuta;
};

export const getNameMunicipalityDestiny = (_item, divipola) => {
  let name = "";
  const foundMunicipality = divipola.map(
    (_itemOrigin) => _itemOrigin.municipalities
  );

  foundMunicipality.forEach((x) => {
    x.forEach((y) => {
      if (y.cod_mpio === _item.municipioDestinoRuta) {
        name = y.nom_mpio;
      }
    });
  });

  return foundMunicipality ? name : _item.municipioDestinoRuta;
};

<script>
import Swal from "sweetalert2";
import { mapState } from "vuex";
import { typeArrivals } from '../constants.js'

export default {
  data() {
    return {
      typeArrivals,
      usuario: {},
      form: {
        selectedTypeArrival: null,
        plate: "",
        selectedSiteIdTerminal: null,
        municipalityOrigin: "",
        departmentOrigin: "",
        municipalityDestination: "",
        departmentDestination: "",
        passengersQuantity: "",
      },
      municipalitiesOptions: {
        origin: [],
        destination: [],
      },
    };
  },
  computed: {
    ...mapState("terminales", [
      "codesTerminal",
      "divipolaCodesDepartamentsAndMunicipalities",
      "passengerOptions",
      "allDataTerminals",
    ]),
  },
  watch: {
    "form.departmentOrigin"(newDept) {
      this.loadMunicipalities(newDept, "origin");
    },
    "form.departmentDestination"(newDept) {
      this.loadMunicipalities(newDept, "destination");
    },
  },
  methods: {
    loadMunicipalities(departmentCode, type) {
      const department = this.divipolaCodesDepartamentsAndMunicipalities.find(
        (dept) => dept.cod_depto === departmentCode
      );
      this.municipalitiesOptions[type] = department
        ? department.municipalities
        : [];
    },
    async handleCreateArrivals(e) {
      e.preventDefault();

      const data = {
        empresaId: this.usuario.empresa.id,
        terId: this.form.selectedSiteIdTerminal,
        nitEmpresaTppc: "123456789-0",
        placa: this.form.plate,
        tipoLlegada: this.form.selectedTypeArrival,
        fechaLlegada: this.$moment
          .tz(new Date(), "America/Bogota")
          .format("YYYY-MM-DDTHH:mm"),
        horaLlegada: this.$moment.tz(new Date(), "America/Bogota").format("HH"),
        minutoLlegada: this.$moment
          .tz(new Date(), "America/Bogota")
          .format("mm"),
        pasajerosLlegada: this.form.passengersQuantity,
        dptoOrigenRuta: this.form.departmentOrigin,
        municipioOrigenRuta: this.form.municipalityOrigin,
        dptoDestinoRuta: this.form.departmentDestination,
        municipioDestinoRuta: this.form.municipalityDestination,
      };

      try {
        const response = await this.$store.dispatch("hl_post", {
          path: "Terminales/IngresoLlegadas",
          data,
        });
        if (!response.success)
          throw new Error(
            "Error al crear llegada, por favor intente nuevamente. Si el error persiste, comuníquese con soporte técnico."
          );
        this.allDataTerminals["Llegadas"].push(data);
        this.$bvModal.hide("modalTerminales");
        Swal.fire(
          "Llegada creada!",
          "La llegada ha sido creada con éxito.",
          "success"
        );
      } catch (error) {
        Swal.fire("Error al crear llegada!", error.message, "error");
      } finally {
        this.resetForm();
      }
    },

    resetForm() {
      this.form = {
        selectedTypeArrival: null,
        plate: "",
        selectedSiteIdTerminal: null,
        hourArrival: "",
        minuteArrival: "",
        municipalityOrigin: "",
        departmentOrigin: "",
        municipalityDestination: "",
        departmentDestination: "",
        passengersQuantity: "",
      };
      this.municipalitiesOptions.origin = [];
      this.municipalitiesOptions.destination = [];
    },
  },
  mounted() {
    this.usuario = JSON.parse(localStorage.getItem("setPersonaAct"));
  },
};
</script>

<template>
  <b-container fluid>
    <form @submit="handleCreateArrivals">
      <b-row>
        <b-col sm="12">
          <card>
            <!-- Section form 1-->
            <div class="row mt-4">
              <div class="col-12 col-lg-6 mb-3">
                <label for="" class="form-label"> Tipo de llegada </label>
                <v-select
                  v-model="form.selectedTypeArrival"
                  class="style-chooser"
                  :options="typeArrivals"
                  :reduce="(option) => option.id"
                  placeholder="-- Selecciona tipo de llegada --"
                >
                  <!-- required -->
                  <template #search="{ attributes, events }">
                    <input
                      class="vs__search"
                      :required="!form.selectedTypeArrival"
                      v-bind="attributes"
                      v-on="events"
                    />
                  </template>
                  <!-- sin resultados en la busquedad -->
                  <template v-slot:no-options="{ search, searching }">
                    <template v-if="searching">
                      No se encontraron resultados para
                      <em class="text-danger">{{ search }}</em
                      >.
                    </template>
                    <em v-else style="opacity: 0.5"
                      >No hay datos para mostrar.</em
                    >
                  </template>
                </v-select>
              </div>

              <div class="col-12 col-lg-6 mb-3">
                <label for=" " class="form-label">Placa</label>
                <input
                  v-model="form.plate"
                  type="text"
                  placeholder="Ej: 1234ABC"
                  class="form-control"
                  maxlength="7"
                  required
                />
              </div>
            </div>
            <!-- Section end form 1-->

            <!-- Section form 0.5 -->
            <div class="row mt-4">
              <div class="col-12 mb-3">
                <label for="" class="form-label"> Sede terminal </label>
                <v-select
                  v-model="form.selectedSiteIdTerminal"
                  class="style-chooser"
                  :options="this.$store.state.terminales.codesTerminal"
                  :reduce="(value) => value.id"
                  :get-option-label="(option) => option.razonSocial"
                  placeholder="-- Selecciona la sede --"
                >
                  <!-- required -->
                  <template #search="{ attributes, events }">
                    <input
                      class="vs__search"
                      :required="!form.selectedSiteIdTerminal"
                      v-bind="attributes"
                      v-on="events"
                    />
                  </template>
                  <!-- sin resultados en la busquedad -->
                  <template v-slot:no-options="{ search, searching }">
                    <template v-if="searching">
                      No se encontraron resultados para
                      <em class="text-danger">{{ search }}</em
                      >.
                    </template>
                    <em v-else style="opacity: 0.5"
                      >No hay datos para mostrar.</em
                    >
                  </template>
                </v-select>
              </div>
            </div>
            <!-- End Section form 0.5 -->

            <!-- Section form 3 -->
            <div class="row mt-4">
              <div class="col-12 col-lg-6 mb-3">
                <label for=" " class="form-label">Departamento de origen</label>
                <v-select
                  v-model="form.departmentOrigin"
                  class="style-chooser"
                  :options="divipolaCodesDepartamentsAndMunicipalities"
                  :reduce="(value) => value.cod_depto"
                  :get-option-label="(option) => option.dpto"
                  placeholder="-- Selecciona el Departamento --"
                >
                  <!-- required -->
                  <template #search="{ attributes, events }">
                    <input
                      class="vs__search"
                      :required="!form.departmentOrigin"
                      v-bind="attributes"
                      v-on="events"
                    />
                  </template>
                  <!-- sin resultados en la busquedad -->
                  <template v-slot:no-options="{ search, searching }">
                    <template v-if="searching">
                      No se encontraron resultados para
                      <em class="text-danger">{{ search }}</em
                      >.
                    </template>
                    <em v-else style="opacity: 0.5"
                      >No hay datos para mostrar.</em
                    >
                  </template>
                </v-select>
              </div>

              <div class="col-12 col-lg-6 mb-3">
                <label for=" " class="form-label">Municipio de origen</label>
                <v-select
                  v-model="form.municipalityOrigin"
                  class="style-chooser"
                  :options="municipalitiesOptions.origin"
                  :reduce="(value) => value.cod_mpio"
                  :get-option-label="(option) => option.nom_mpio"
                  placeholder="-- Selecciona el Municipio --"
                >
                  <!-- required -->
                  <template #search="{ attributes, events }">
                    <input
                      class="vs__search"
                      :required="!form.municipalityOrigin"
                      v-bind="attributes"
                      v-on="events"
                    />
                  </template>
                  <!-- sin resultados en la busquedad -->
                  <template v-slot:no-options="{ search, searching }">
                    <template v-if="searching">
                      No se encontraron resultados para
                      <em class="text-danger">{{ search }}</em
                      >.
                    </template>
                    <em v-else style="opacity: 0.5"
                      >Selecciona el Departamento de Origen.</em
                    >
                  </template>
                </v-select>
              </div>
            </div>
            <!-- Section end form 3 -->

            <!-- Section form 4 -->
            <div class="row mt-4">
              <div class="col-12 col-lg-6 mb-3">
                <label for=" " class="form-label"
                  >Departamento de Destino
                </label>
                <v-select
                  v-model="form.departmentDestination"
                  class="style-chooser"
                  :options="divipolaCodesDepartamentsAndMunicipalities"
                  :reduce="(value) => value.cod_depto"
                  :get-option-label="(option) => option.dpto"
                  placeholder="-- Selecciona el Departamento --"
                >
                  <!-- required -->
                  <template #search="{ attributes, events }">
                    <input
                      class="vs__search"
                      :required="!form.departmentDestination"
                      v-bind="attributes"
                      v-on="events"
                    />
                  </template>
                  <!-- sin resultados en la busquedad -->
                  <template v-slot:no-options="{ search, searching }">
                    <template v-if="searching">
                      No se encontraron resultados para
                      <em class="text-danger">{{ search }}</em
                      >.
                    </template>
                    <em v-else style="opacity: 0.5"
                      >No hay datos para mostrar.</em
                    >
                  </template>
                </v-select>
              </div>

              <div class="col-12 col-lg-6 mb-3">
                <label for=" " class="form-label">Municipio de destino</label>
                <v-select
                  v-model="form.municipalityDestination"
                  class="style-chooser"
                  :options="municipalitiesOptions.destination"
                  :reduce="(value) => value.cod_mpio"
                  :get-option-label="(option) => option.nom_mpio"
                  placeholder="-- Selecciona el Municipio --"
                >
                  <!-- required -->
                  <template #search="{ attributes, events }">
                    <input
                      class="vs__search"
                      :required="!form.municipalityDestination"
                      v-bind="attributes"
                      v-on="events"
                    />
                  </template>
                  <!-- sin resultados en la busquedad -->
                  <template v-slot:no-options="{ search, searching }">
                    <template v-if="searching">
                      No se encontraron resultados para
                      <em class="text-danger">{{ search }}</em
                      >.
                    </template>
                    <em v-else style="opacity: 0.5"
                      >Selecciona el Departamento de Destino.</em
                    >
                  </template>
                </v-select>
              </div>
            </div>
            <!-- Section end form 4 -->

            <!-- Section form 5 -->
            <div class="row mt-4">
              <div class="col-12 mb-3">
                <label for=" " class="form-label">Cantidad de pasajeros</label>
                <v-select
                  v-model="form.passengersQuantity"
                  class="style-chooser"
                  :options="passengerOptions"
                  :reduce="(value) => value.id"
                  placeholder="-- Selecciona cantidad de pasajeros --"
                >
                  <!-- required -->
                  <template #search="{ attributes, events }">
                    <input
                      class="vs__search"
                      :required="!form.passengersQuantity"
                      v-bind="attributes"
                      v-on="events"
                    />
                  </template>
                  <!-- sin resultados en la busquedad -->
                  <template v-slot:no-options="{ search, searching }">
                    <template v-if="searching">
                      No se encontraron resultados para
                      <em class="text-danger">{{ search }}</em
                      >.
                    </template>
                    <em v-else style="opacity: 0.5"
                      >No hay datos para mostrar.</em
                    >
                  </template>
                </v-select>
              </div>
            </div>
            <!-- End Section form 5 -->

            <div class="d-flex justify-content-end pt-5">
              <vs-button
                type="submit"
                class="py-1"
                animation-type="vertical"
                gradient
                size="large"
              >
                Agregar Llegada
              </vs-button>
            </div>
          </card>
        </b-col>
      </b-row>
    </form>
  </b-container>
</template>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row",staticStyle:{"gap":"0.5rem"}},_vm._l((_vm.allDataTerminals['Alcoholimetrias']),function(item){return _c('card',{key:item.id,staticClass:"bg-dark p-3 border col-12 col-lg-2"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 d-flex justify-content-end"},[_c('i',{staticClass:"fas fa-address-card"})]),_c('div',{staticClass:"col-12"},[_c('small',{staticClass:"text-white-50"},[_vm._v("Info. Conductor")]),_c('h5',[_vm._v(_vm._s(item.nombreConductor))]),_c('p',[_vm._v(" "+_vm._s(item.numeroDocumentoConductor)+" ")])]),_c('div',{staticClass:"col-12 d-flex flex-column align-items-start",staticStyle:{"gap":"0.8rem"}},[_c('div',[_c('small',{staticClass:"text-white-50"},[_vm._v("Resultado prueba")]),_c('br'),_c('span',{class:`badge ${
              item.resultadosPrueba === 1
                ? 'badge-success'
                : 'badge-secondary'
            }`},[_vm._v(" "+_vm._s(item.resultadosPrueba === 1 ? "Positivo" : "Negativo")+" ")])]),_c('div',[_c('small',{staticClass:"text-white-50"},[_vm._v("Examen medico")]),_c('br'),_c('span',{class:`badge ${
              item.resultadosExamen === 1
                ? 'badge-success'
                : 'badge-secondary'
            }`},[_vm._v(" "+_vm._s(item.resultadosExamen === 1 ? "Aprobado" : "No aprobado")+" ")])])]),_c('div',{staticClass:"col-12 pt-2"},[_c('small',{staticClass:"text-white-50"},[_vm._v("Fecha Alcoholimetria")]),_c('br'),_c('small',[_vm._v(" "+_vm._s(_vm.$moment .tz(item.fechaPrueba, "America/Bogota") .format("D MMM, YYYY / hh:mm A"))+" ")])])])])}),1)
}
var staticRenderFns = []

export { render, staticRenderFns }
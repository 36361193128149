<script>
// import { data as mocksTest } from "./utils/__mocks__.js";
import { mapState } from "vuex";

export default {
  data() {
    return {
      // mocksTest,
    };
  },
  computed: {
    ...mapState("terminales", ["allDataTerminals"]),
  },
};
</script>

<template>
  <div class="row" style="gap: 0.5rem">
    <card
      v-for="item of allDataTerminals['Alcoholimetrias']"
      :key="item.id"
      class="bg-dark p-3 border col-12 col-lg-2"
    >
      <div class="row">
        <div class="col-12 d-flex justify-content-end">
          <i class="fas fa-address-card"></i>
        </div>
        <div class="col-12">
          <small class="text-white-50">Info. Conductor</small>
          <h5>{{ item.nombreConductor }}</h5>
          <p>
            {{ item.numeroDocumentoConductor }}
          </p>
        </div>
        <div
          class="col-12 d-flex flex-column align-items-start"
          style="gap: 0.8rem"
        >
          <div>
            <small class="text-white-50">Resultado prueba</small>
            <br />
            <span
              :class="`badge ${
                item.resultadosPrueba === 1
                  ? 'badge-success'
                  : 'badge-secondary'
              }`"
            >
              {{ item.resultadosPrueba === 1 ? "Positivo" : "Negativo" }}
            </span>
          </div>
          <div>
            <small class="text-white-50">Examen medico</small>
            <br />
            <span
              :class="`badge ${
                item.resultadosExamen === 1
                  ? 'badge-success'
                  : 'badge-secondary'
              }`"
            >
              {{ item.resultadosExamen === 1 ? "Aprobado" : "No aprobado" }}
            </span>
          </div>
        </div>
        <div class="col-12 pt-2">
          <small class="text-white-50">Fecha Alcoholimetria</small>
          <br />
          <small>
            {{
              $moment
                .tz(item.fechaPrueba, "America/Bogota")
                .format("D MMM, YYYY / hh:mm A")
            }}
          </small>
        </div>
      </div>
    </card>
  </div>
</template>
